<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>
    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-text-field
          v-model="shain.code"
          label="社員コード"
          prepend-icon="mdi-account"
          outlined
          readonly
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-text-field
          v-model="shain.name"
          label="社員名"
          prepend-icon="mdi-account"
          outlined
          :readonly="edit"
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-text-field
          v-model="shain.furi"
          label="フリガナ"
          prepend-icon="mdi-account"
          outlined
          :readonly="edit"
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-autocomplete
          v-model="shain.shozoku"
          :items="shozokuList"
          label="所属"
          prepend-icon="mdi-account-cog"
          :rules="[Rules.Required]"
          outlined
          :readonly="edit"
          item-text="name"
          :item-value="item => Number(item.code)"
        ></v-autocomplete>
        <v-autocomplete
          v-model="shain.sex"
          :items="sexList"
          label="性別"
          prepend-icon="mdi-account-cog"
          :rules="[Rules.Required]"
          outlined
          :readonly="edit"
          item-text="name"
          :item-value="item => Number(item.code)"
        ></v-autocomplete>
        <DatePicker
          v-model="shain.daybirth"
          label="生年月日"
          prepend-icon="mdi-calendar"
          type="day"
          outlined
          :disabled="edit"
          :rules="[Rules.Required]"
        />
        <v-autocomplete
          v-model="shain.kkubun"
          :items="salaryList"
          label="給与区分"
          prepend-icon="mdi-account-cog"
          :rules="[Rules.Required]"
          outlined
          :readonly="edit"
          item-text="name"
          :item-value="item => Number(item.code)"
        ></v-autocomplete>
        <v-autocomplete
          v-model="shain.taimode"
          :items="zaiKbnList"
          label="在籍区分"
          prepend-icon="mdi-account-cog"
          :rules="[isNull]"
          outlined
          :readonly="edit"
          item-text="name"
          :item-value="item => Number(item.code)"
        ></v-autocomplete>
        <DatePicker
          v-model="shain.daynyu"
          label="入社年月日"
          prepend-icon="mdi-calendar"
          type="day"
          outlined
          :disabled="edit"
          :rules="[Rules.Required]"
        />
        <DatePicker
          v-model="shain.daytai"
          label="退社年月日"
          prepend-icon="mdi-calendar"
          type="day"
          outlined
          :disabled="edit"
          :rules="[Rules.Required]"
        />
        <!--<v-text-field
          v-model.number="shain.kyuhtan0"
          label="基本単価"
          prepend-icon="mdi-calendar"
          type="number"
          outlined
          :readonly="edit"
          :rules="[isNumber]"
        /> -->
        <v-autocomplete
          v-model="shain.authority"
          :items="authorList"
          label="権限"
          prepend-icon="mdi-account-cog"
          :rules="[Rules.Required]"
          outlined
          :readonly="edit"
          item-text="name"
          item-value="code"
        ></v-autocomplete>
        <v-text-field
          v-model="shain.password"
          label="パスワード"
          prepend-icon="mdi-lock"
          :rules="[Rules.Required]"
          outlined
        ></v-text-field>
        <v-radio-group
          v-model="shain.isValid"
          row
          label="レコード状態:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";

const SelectsDelete = {
  Delete: "0",
  Default: "1"
};

export default {
  name: "ShainDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,

      SelectsDelete,
      shain: {
        incode: "",
        code: "",
        name: "",
        furi: "",
        password: "",
        authority: "",
        kkubun: "",
        taimode: "",
        sex: "",
        shozoku: "",
        daybirth: "",
        daynyu: "",
        daytai: "",
        kyuhtan0: 0,
        oldCode: null,
        isValid: SelectsDelete.Default
      },
      authorList: [],
      salaryList: [],
      zaiKbnList: [],
      sexList: [],
      shozokuList: [],
      isNumber: value => Number.isInteger(value) || "整数で入力してください。",
      isNull: value => value != null || "必須入力です。"
    };
  },
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    },
    canadd() {
      if (this.add === true) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    //更新処理
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.userId);
      this.$loading();
      let pass = "";

      if (this.password === "" || this.password === undefined) {
        //pass = this.params.args.password;
        if (this.add) {
          await this.$error("パスワードを入力してください。");
          this.$unloading();
          return;
        }
      } else {
        pass = this.password;
      }
      /*if (pass === undefined) {
        await this.$error("パスワードを入力してください。");
        this.$unloading();
        return;
      }*/

      try {
        const param = this.shain;

        console.log("submit_param", param);
        if (this.add) {
          //await this.$post(this.Paths.shain, param);
        } else {
          await this.$put(this.Paths.shain, param);
        }
        this.$info("更新しました。", "社員マスタ");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    //Listに戻る
    back() {
      const path = "/list/shain";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    //#region GetAPI
    async getAuthorList() {
      console.log("getAuthorList");
      const params = { kbn: "AUTHOR" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getSalaryList() {
      console.log("getSalaryList");
      const params = { kbn: "SALARY" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getZaiKbnList() {
      console.log("getZaiKbnList");
      const params = { kbn: "ZAIKBN" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getSexList() {
      console.log("getSexList");
      const params = { kbn: "SEX" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getShozokuList() {
      console.log("getShozokuList");
      return await this.$get(this.Paths.shozokuFilter);
    }
    //#endregion
  },
  async created() {
    console.log(this.name, "created");
    const params = this.$route.params;
    const prosess = params.prosess;
    this.params = params;
    console.log("filter", this.params.filter);

    this.$loading();
    try {
      if (prosess && typeof prosess === "string")
        this.params.prosess = prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }
      const promiseList = [
        this.getAuthorList(),
        this.getSalaryList(),
        this.getZaiKbnList(),
        this.getSexList(),
        this.getShozokuList()
      ];
      const [auth, salary, zaikbn, sex, shozoku] = await Promise.all(
        promiseList
      );

      this.authorList = auth;
      this.salaryList = salary;
      this.zaiKbnList = zaikbn;
      this.sexList = sex;
      this.shozokuList = shozoku;

      if (!this.add) {
        const args = this.args;
        this.shain = args;
        // this.incode = args.incode;
        // this.code = args.code;
        // this.name = args.name;
        // this.furi = args.furi;
        // this.password = args.password;
        // this.isValid = Number(args.isValid);
        // this.authority = args.authority;
        // this.kkubun = args.kkubun;
        // this.taimode = args.taimode;
        // this.sex = args.sex;
        // this.shozoku = args.shozoku;
        // this.daybirth = args.daybirth;
        // this.daybirth = args.daybirth;
        // this.daytai = args.daytai;
        // this.kyuhtan0 = args.kyuhtan0;
        // this.oldCode = args.oldCode;

        console.log("promise");
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
